<template>
  <v-container
    fluid
    tag="section"
  >
    <div v-if="!isMaster">
      Kein Zugriff!
    </div>
    <v-card v-else flat>
      <v-data-table
        :headers="[
          { text: 'Nachname', value: 'familyName' },
          { text: 'Vorname', value: 'givenName' },
          { text: 'Dopplungen', value: 'duplicates.length' }
        ]"
        :items="personen"
        sort-by="familyName"
        :items-per-page="-1"
        @click:row="open"
      />
    </v-card>

    <base-material-dialog
      :value="dialog.open"
      icon="far fa-merge"
      title="Personen zusammenführen"
      color="primary"
      :actions="[ master !== null && merge.length > 0 ? 'save' : '', 'close' ]"
      @close="close"
      @save="save"
      @esc="close"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #header.p0>
          Person 1<br>
          <v-radio-group v-model="dialog.master">
            <v-radio label="Master" :value="0" />
          </v-radio-group>
          <v-checkbox
            v-model="dialog.merge"
            label="Zusammenfügen"
            value="0"
          />
        </template>
        <template #header.p1>
          Person 2<br>
          <v-radio-group v-model="dialog.master">
            <v-radio label="Master" :value="1" />
          </v-radio-group>
          <v-checkbox
            v-model="dialog.merge"
            label="Zusammenfügen"
            value="1"
          />
        </template>
        <template #header.p2>
          Person 3<br>
          <v-radio-group v-model="dialog.master">
            <v-radio label="Master" :value="2" />
          </v-radio-group>
          <v-checkbox
            v-model="dialog.merge"
            label="Zusammenfügen"
            value="2"
          />
        </template>
        <template #header.p3>
          Person 4<br>
          <v-radio-group v-model="dialog.master">
            <v-radio label="Master" :value="3" />
          </v-radio-group>
          <v-checkbox
            v-model="dialog.merge"
            label="Zusammenfügen"
            value="3"
          />
        </template>
        <template #header.p4>
          Person 4<br>
          <v-radio-group v-model="dialog.master">
            <v-radio label="Master" :value="4" />
          </v-radio-group>
          <v-checkbox
            v-model="dialog.merge"
            label="Zusammenfügen"
            value="4"
          />
        </template>

      </v-data-table>
      <p>Hinweis: Der Master wird als Ausgangsdatensatz benutzt und von allen anderen markierten Datensätze die zusätzlichen Daten übernommen.</p>
    </base-material-dialog>
  </v-container>
</template>

<script>
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'
import { TPsport } from '@/enum'

const query = `
  _id
  familyName
  givenName
  birthDate
  judgelicence { type gender validFrom validUntil }
  turnportal { type }
  email { type value }
  telephone { type value { DIN } }
  address { type value { streetNumber streetAddress postalCode addressLocality addressCountry } }
  acl { type id startDate endDate }
  _memberOf
  identifiers { name value }
  duplicates {
    _id
    familyName
    givenName
    birthDate
    judgelicence { type gender validFrom validUntil }
    turnportal { type }
    email { type value }
    telephone { type value { DIN } }
    address { type value { streetNumber streetAddress postalCode addressLocality addressCountry } }
    acl { type id startDate endDate }
    _memberOf
    identifiers { name value }
  }
`

export default {
  name: 'merge',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    PersonFind: [],
    filter: '',
    dialog: {
      open: false,
      data: null,
      master: -1,
      merge: []
    }
  }),

  computed: {
    ...mapGetters(['isMaster']),
    personen () {
      return this.PersonFind.filter((p, i, arr) => arr.findIndex(a => a.duplicates.find(d => d._id === p._id)) < i)
    },
    headers () {
      return [
        { text: '', value: 'text', sortable: false, align: 'right' },
        ...(this.dialog.data || []).map((_, i) => ({ text: `Person ${i + 1}`, value: `p${i}`, sortable: false, align: 'left' }))
      ]
    },
    items () {
      return [
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr._id; return acc }, { text: 'ID' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.givenName; return acc }, { text: 'Vorname' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.familyName; return acc }, { text: 'Nachname' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.birthDate; return acc }, { text: 'Geburtsdatum' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.judgelicence?.map(kl => kl.type + ' (' + kl.gender + ', ' + kl.validFrom + '-' + kl.validUntil + ')').join(', '); return acc }, { text: 'Kampfrichterlizenz' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.turnportal?.map(tp => TPsport.find(s => s.id === tp.type)?.value).join(', '); return acc }, { text: 'Turnportal' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.email?.map(e => e.value + ' (' + e.type + ')').join(', '); return acc }, { text: 'E-Mail' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.telephone?.map(e => e.value.DIN + ' (' + e.type + ')').join(', '); return acc }, { text: 'Telefon' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.address?.map(e => e.value.streetAddress + ' ' + e.value.streetNumber + ' (' + e.type + ')').join(', '); return acc }, { text: 'Adresse' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.acl?.map(e => e.id + ' (' + e.type + ')').join(', '); return acc }, { text: 'ACL' }),
        (this.dialog.data || []).reduce((acc, curr, i) => { acc[`p${i}`] = curr.identifiers?.map(e => e.name + ': ' + e.value).join(', '); return acc }, { text: 'Identifier' })
      ]
    },
    master () {
      return this.dialog.data?.[this.dialog.master]?._id || null
    },
    merge () {
      return this.dialog.merge.map(i => this.dialog.data?.[i]?._id).filter(id => id !== this.master)
    }
  },

  methods: {
    open (data) {
      this.dialog.data = [
        { ...data, duplicates: undefined },
        ...data.duplicates
      ]
      this.dialog.master = -1
      this.dialog.merge = []
      this.dialog.open = true
    },
    close () {
      this.dialog.open = false
      this.dialog.data = null
      this.dialog.master = -1
      this.dialog.merge = []
    },
    save () {
      this.mutate({
        mutation: gql`
          mutation($master: UUID!, $merge: [UUID!]!) {
            PersonMerge(master: $master, merge: $merge) { _id }
          }
        `,
        variables: {
          master: this.master,
          merge: this.merge
        }
      })

      this.close()
    }
  },

  apollo: {
    PersonFind: {
      query: gql`
        query {
          PersonFind(duplicates: true, limit: -100000) { ${query} }
        }`,
      variables () {
        return {
          limit: 1000
        }
      },
      subscribeToMore: {
        document: gql`
          subscription { PersonCreate { ${query} }}
          `,
        updateQuery: updateQuery('PersonFind', 'PersonCreate')
      }
    },
    $subscribe: {
      PersonUpdate: {
        query: gql`
          subscription { PersonUpdate { ${query} }}
          `
      },
      PersonDelete: {
        query: gql`
          subscription { PersonDelete }
          `,
        result (id) {
          deleteQuery('PersonFind', 'PersonDelete', this.PersonFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
